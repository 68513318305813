<template>
    <v-container>
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Permisos</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="permissions"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="showCourses(item)"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showCourses(item)"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- v-can="'accounts create'" -->
        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-btn
                    icon
                    dark
                    @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Nuevo permiso</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="save"
                        >
                            Guardar
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-form>
                                <v-text-field
                                label="Nombre"
                                v-model="permission.name"
                                ></v-text-field>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {mapMutations} from "vuex"
    // import DataTable from '@/components/Datatable.vue'

    export default {
        data() {
            return {
                dialog: false,
                headers: [
                    {
                        text: 'Nombre',
                        value: 'name'
                    },
                    {
                        text: 'Acciones',
                        value: 'actions',
                        width: '5%',
                        sortable: false
                    },
                ],
                search: '',
                permission: {
                    name: '',
                },
                permissions: []
            }
        },
        methods: {
            ...mapMutations(['setLayout','setNotify','setLoading']),
            get() {
                this.setLoading(true)

                this.axios.get('/permissions')
                .then( (response) => {
                    this.setLoading(false)

                    this.permissions = response.data.permissions
                })
                .catch( (error) => {
                    // this.loadGrade = false
                    this.setLoading(false)
                    console.log(error)
                })
            },
            save() {
                this.setLoading(true)

                this.axios.post('/permissions',this.permission)
                .then( () => {
                    this.permission.name = ''
                    this.setLoading(false)
                    this.get()
                    this.dialog = false
                })
                .catch( (error) => {
                    this.dialog = false
                    this.setLoading(false)
                    console.log(error)
                })
            },
            showCourses(t) {
                console.log(t)
            },
            closeDialog() {
                this.dialog = false
                this.selected = []
            }
        },
        created(){
            this.setLayout('main-layout')
        },
        mounted(){
            this.get()
            // this.getPermissions()
        },
        components: {
            // DataTable
        }
    }
</script>
